import "./ButtonWhatsApp.scss";

export const ButtonWhatsApp = () => {
  return (
    <div>
      <div className="rounded-circle chat-circle shadow">
        <a
          href="https://api.whatsapp.com/message/4ZFYNZUHMVFKK1?autoload=1&app_absent=0"
          target="_blank"
          rel="noreferrer"
        >
          <i className="bi bi-whatsapp fs-1 text-white m-auto"></i>
        </a>
      </div>
    </div>
  );
};
