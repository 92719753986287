import React, { useState } from "react";

import "./Contacts.scss";

import header from "./../../assets/images/contacts/header.png";
import iFacebook from "./../../assets/images/contacts/icono-facebook.png";
import iInstagram from "./../../assets/images/contacts/icono-instagram.png";
import iTikTok from "./../../assets/images/contacts/icono-tiktok.png";
import iYouTube from "./../../assets/images/contacts/icono-youtube.png";
import form from "./../../assets/images/contacts/formulario.png";

import { SectionContacts } from "../../components/shared/SectionContacts";

export const Contacts = () => {
  const [valueStar, setValueStar] = useState(0);
  const totalStars = 5;

  return (
    <div className="container-fluid">
      {/* Header */}
      <div className="row">
        <div className="col postion-relative p-0">
          <img src={header} alt="header" width="100%" />
        </div>
      </div>
      {/* Contacts */}
      <SectionContacts bgColor={"bg-primary"} textColor={"text-white"} />
      {/* Redes Sociales */}
      <div className="row bg-secondary text-center p-5">
        <div className="col">
          <h1 className="mb-5">¡ Síguenos en nuestras redes sociales !</h1>
          <div className="row mb-4 justify-content-center">
            <div className="col-5 col-md-3 col-lg-2 mb-3 mb-md-0">
              <a
                href="https://www.facebook.com/vmservicexpress"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={iFacebook} alt="iFacebook" width={"60%"} />
              </a>
            </div>
            <div className="col-5 col-md-3 col-lg-2 mb-3 mb-md-0">
              <a
                href="https://www.instagram.com/vm.servicio_expres/"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={iInstagram} alt="iInstagram" width={"60%"} />
              </a>
            </div>
            <div className="col-5 col-md-3 col-lg-2">
              <a
                href="https://www.tiktok.com/@vmservicioexpres?is_from_webapp=1&sender_device=pc"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={iTikTok} alt="iTikTok" width={"60%"} />
              </a>
            </div>
            <div className="col-5 col-md-3 col-lg-2">
              <a
                href="https://www.youtube.com/channel/UC2wB-xiKaCGWMfc5UtbSBuA/featured"
                target={"_blank"}
                rel="noreferrer"
              >
                <img src={iYouTube} alt="iYouTube" width={"60%"} />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <span className="fs-4">Encuéntranos como</span>
              <h1>V&M SERVICIO EXPRESS</h1>
            </div>
          </div>
        </div>
      </div>
      {/* Formulario */}
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="row justify-content-center p-5">
            {/* Form */}
            <div className="col-md-6 col-lg-5">
              <h2 className="text-center mb-5">¡Déjenos tu Opinión!</h2>
              <form
                name="form1"
                method="POST"
                action="mailto:serviceexpresvm@gmail.com?subject=Formulario de Contacto"
                encType="text/plain"
              >
                {/*-- Nombre --*/}
                <div className="mb-3">
                  <label htmlFor="subject" className="form-label fs-4">
                    <h4>Nombre</h4>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    placeholder="Ingrese su nombre"
                    name="Nombre"
                    maxLength={120}
                  />
                </div>
                {/*-- Correo --*/}
                <div className="mb-3">
                  <label htmlFor="mail" className="form-label fs-4">
                    <h4>Correo Eléctronico</h4>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="mail"
                    placeholder="Ingrese su correo"
                    name="Correo Electronico"
                  />
                </div>
                {/*-- Opinión --*/}
                <div className="mb-3">
                  <label htmlFor="message" className="form-label fs-4">
                    <h4>Opinión</h4>
                  </label>
                  <textarea
                    className="form-control"
                    id="message"
                    rows="3"
                    name="Mensaje"
                    maxLength={120}
                  ></textarea>
                </div>
                <input
                  id="stars"
                  type="hidden"
                  value={valueStar}
                  name="Calificacion"
                />
                {/*-- Calificacion --*/}
                <h4 className="mb-0">Calificación</h4>
                <div className="panel-star mb-3">
                  {[...new Array(totalStars)].map((val, index) => {
                    return index < valueStar ? (
                      <i
                        style={{ color: "gold" }}
                        key={index}
                        className="bi bi-star-fill i-star fs-2"
                        onClick={() => {
                          setValueStar(index + 1);
                        }}
                      ></i>
                    ) : (
                      <i
                        key={index}
                        className="bi bi-star i-star fs-2"
                        onClick={() => {
                          setValueStar(index + 1);
                        }}
                      ></i>
                    );
                  })}
                </div>
                <div className="text-end">
                  <button type="submit" className="btn btn-primary btn-lg">
                    Enviar
                  </button>
                </div>
              </form>
            </div>
            {/* Img */}
            <div className="col-8 col-md-6 col-lg-5 d-flex">
              <img
                src={form}
                alt="form"
                width={"80%"}
                className="mt-auto mx-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
