import "./Business.scss";

import service2 from "../../assets/images/services/servicio2.png";
import service3 from "../../assets/images/services/servicio3.png";

export const Business = () => {
  return (
    <div className="container-fluid text-center">
      {/* Header */}
      <div className="row bg-secondary">
        <div className="col bg-header">
          <div className="row pt-5">
            <div className="col-md-8 col-lg-6 col-xl-5 ms-auto">
              <h1>V&M SERVICIO EXPRESS</h1>
              <p className="fs-5">
                Somos una empresa 100% Ecuatoriana fundada en 2017.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* History */}
      <div className="row bg-primary py-5">
        <div className="col-11 col-md-9 col-lg-6 m-auto">
          <h1 className="mb-4 text-white">NUESTRA HISTORIA</h1>
          <p className="text-white fs-5">
            Somos una empresa 100% Ecuatoriana fundada en 2017, dedicada al
            alquiler de autos particulares y comerciales para el traslado de
            personas, mercaderías y documentos, en las ciudades de Quito e
            Ibarra (compartido) y servicio exclusivo a nivel nacional.
          </p>
        </div>
      </div>
      {/* Team */}
      <div className="row bg-secondary py-5 mb-5">
        <div className="col-11 col-md-9 col-lg-6 m-auto">
          <h1 className="mb-4">NUESTRO EQUIPO</h1>
          <p className="fs-5">
            Nuestro personal cuenta con más de 10 años de experiencia de
            conducción, traslado de personas y en el manejo y entrega de
            paquetería y documentos con la mayor profesionalidad y puntualidad.
          </p>
        </div>
      </div>
      {/* Mision */}
      <div className="row mb-5">
        <div className="col-11 col-md-10 bg-secondary mx-auto">
          <div className="row px-5 py-4">
            <div className="col-md-8 col-lg-7 mx-auto mb-3 mb-lg-3">
              <img src={service3} alt="service3" width={"100%"} />
            </div>
            <div className="col-lg-5 my-auto">
              <h2>NUESTRA MISIÓN</h2>
              <p className="fs-5">
                Realizar servicio puerta a puerta, con una buena atención,
                horarios cumplidos, organización, realizar su viaje de una forma
                mas segura, responsable, eficiente y con las mejores tarifas del
                mercado.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* Vision */}
      <div className="row">
        <div className="col-11 col-md-10 bg-primary mx-auto text-white">
          <div className="row px-5 py-4">
            <div className="col-lg-5 my-auto">
              <h2 className="text-white">NUESTRA VISIÓN</h2>
              <p className="text-white fs-5">
                Posicionarnos como la empresa de Transporte y Seguridad Personal
                y Paquetería número 1 en Ecuador.
              </p>
            </div>
            <div className="col-md-8 col-lg-7 mx-auto mt-3 mt-lg-3">
              <img src={service2} alt="service2" width={"100%"} />
            </div>
          </div>
        </div>
      </div>
      {/* Titulo Valores */}
      <div className="row py-5 text-center">
        <div className="col">
          <h1>¡NUESTROS VALORES!</h1>
        </div>
      </div>
      {/* Valores */}
      <div className="row mb-5">
        <div className="col-11 col-md-10 mx-auto text-white">
          <div className="row mb-3">
            <div className="col-md bg-primary p-4 mb-3 mb-lg-0">
              <h2 className="text-secondary">RESPONSABILIDAD</h2>
              <p className="text-white fs-5">
                Cumplimos con los viajes de manera segura y responsable, nos
                encargamos que llegues a tu destino con toda Seguridad.
                Trabajamos para ti y por ti!
              </p>
            </div>
            <div className="col-md bg-primary p-4 ms-md-3 mb-3 mb-lg-0 mx-lg-3">
              <h2 className="text-secondary">PUNTUALIDAD</h2>
              <p className="text-white fs-5">
                Nuestra mayor prioridad es que llegue a su lugar final a la hora
                establecida, cumplimos cabalmente con toda seguridad y a tiempo
                por nuestros clientes.
              </p>
            </div>
            <div className="col bg-primary p-4">
              <h2 className="text-secondary">HONORABILIDAD</h2>
              <p className="text-white fs-5">
                Todos nuestros conductores cumplen a cabalidad con sus deberes
                con todos nuestros clientes.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md bg-primary p-4 me-3 mb-3 mb-md-0">
              <h2 className="text-secondary">BUEN SERVICIO AL CLIENTE</h2>
              <p className="text-white fs-5">
                Contamos con un equipo de trabajo empático y colaborativo, para
                que nuestros clientes se sientan a gusto con nuestros servicios,
                mantenemos una sana convivencia para que nuestro ambiente sea
                agradable y saludable.
              </p>
            </div>
            <div className="col-md bg-primary p-4 d-flex">
              <div className="m-auto">
                <h2 className="text-secondary">HONRADEZ</h2>
                <p className="text-white fs-5">
                  Somos justos con nuestros precios y servicios realizados.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Valores */}
    </div>
  );
};
