import "./Footer.scss";
import React from "react";
import simbolo from "../../../assets/simbolo.png";
import { ButtonTop } from "./../ButtonTop";
import { ButtonWhatsApp } from "../ButtonWhatsApp";
import { ButtonApp } from "../ButtonApp";

export const Footer = () => {
  return (
    <footer className="bg-primary">
      <ButtonTop />
      <ButtonWhatsApp />
      <ButtonApp />
      <div className="container-fluid">
        <div className="row px-md-5 pt-5">
          {/*-- Logotipo --*/}
          <div className="col-md-4 text-center">
            <div className="position-relative panel-logotipo">
              <div className="bg-simbolo rounded-circle">
                <a href="/" className="stretched-link">
                  <img src={simbolo} alt="simbolo" width="100%" />
                </a>
              </div>
              <h5 className="text-uppercase mb-4 font-weight-bold text-white">
                Servicio Express
              </h5>
            </div>
            <p className="text-light" style={{ fontSize: ".9rem" }}>
              V&M Servicio Express es una empresa constituido para brindar
              seguridad en los diferentes traslados a todos nuestros clientes y
              lleguen a su destino a tiempo
            </p>
          </div>
          {/*-- Acerca de --*/}
          <div className="col-md-3 offset-md-1 text-center text-md-start">
            <h5 className="text-uppercase mb-4 text-secondary">Acerca de</h5>
            <p>
              <a href="/servicios" className="text-white text-decoration-none">
                Servicios
              </a>
            </p>
            <p>
              <a href="/contactos" className="text-white text-decoration-none">
                Contactos
              </a>
            </p>
            <p>
              <a href="/empresa" className="text-white text-decoration-none">
                Empresa
              </a>
            </p>
          </div>
          {/*-- Contactos --*/}
          <div className="col-md-4 text-center text-md-start">
            <h5 className="text-uppercase mb-4 text-secondary">Contactos</h5>
            <p>
              <a
                href="/contactos"
                className="btn-floating btn-sm text-white text-decoration-none"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="bi bi-house-door-fill me-2 text-white"></i>Av.
                Amaru Ñan y Calle I, Quito, Ecuador
              </a>
            </p>
            <p>
              <a
                href="mailto:serviceexpresvm@gmail.com"
                className="btn-floating btn-sm text-white text-decoration-none"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="bi bi-envelope-at-fill me-2 text-white"></i>
                serviceexpresvm@gmail.com
              </a>
            </p>
            <p>
              <a
                href="https://api.whatsapp.com/message/4ZFYNZUHMVFKK1?autoload=1&app_absent=0"
                className="btn-floating btn-sm text-white text-decoration-none"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="bi bi-telephone-fill me-2 text-white"></i>099 986
                9926
              </a>
            </p>
            <p>
              <a
                href="/contactos"
                className="btn-floating btn-sm text-white text-decoration-none"
                target={"_blank"}
                rel="noreferrer"
              >
                <i className="bi bi-telephone-fill me-2 text-white"></i>098 188
                8449
              </a>
            </p>
          </div>
        </div>
        {/*-- Footer --*/}
        <hr className="text-white" />
        <div className="row px-md-4">
          {/*-- Empresa --*/}
          <div className="col-md-4 m-auto">
            <div className="text-center text-md-start">
              <p className="m-0">
                &copy; {new Date().getFullYear()}
                <strong> V&M Servicio Express</strong>
              </p>
              <p>Todos los Derechos reservados</p>
            </div>
          </div>
          {/*-- Powered By --*/}
          <div className="col-12 col-md-4 text-center">
            <div className="powered-by mb-3">
              <a
                href="https://wallpanadev.com/"
                target="_blank"
                rel="noreferrer"
                className="fw-bold text-decoration-none text-black"
              >
                Powered By
                <br />
                <span>WALLPANA</span>
                <span>
                  DEV<span> S.A.S. B.I.C.</span>
                </span>
              </a>
            </div>
          </div>
          {/*-- Redes Sociales --*/}
          <div className="col-md-4 m-auto">
            <div className="text-center text-md-end">
              <ul className="list-unstyled list-inline">
                <li className="list-inline-item">
                  <a
                    href="https://www.facebook.com/vmservicexpress"
                    className="btn-floating btn-sm text-white"
                    target="_blank"
                    rel="noreferrer"
                    title="Facebook"
                  >
                    <i className="bi bi-facebook"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.instagram.com/vm.servicio_expres/"
                    className="btn-floating btn-sm text-white"
                    target="_blank"
                    rel="noreferrer"
                    title="Instagram"
                  >
                    <i className="bi bi-instagram"></i>
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    href="https://www.youtube.com/channel/UC2wB-xiKaCGWMfc5UtbSBuA/featured"
                    className="btn-floating btn-sm text-white"
                    target="_blank"
                    rel="noreferrer"
                    title="YouTube"
                  >
                    <i className="bi bi-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
