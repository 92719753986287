import "./Services.scss";

import exclusivePrices from "./preciosExclusivos.json";

import appMovil from "../../assets/images/app-movil.png";
import headerServices from "../../assets/images/services/h-servicios.png";
import service1 from "../../assets/images/services/servicio1.png";
import service2 from "../../assets/images/services/servicio2.png";
import service3 from "../../assets/images/services/servicio3.png";
import service4 from "../../assets/images/services/servicio4.png";
import { SectionQr } from "../../components/shared/SectionQr";

export const Services = () => {
  return (
    <div className="container-fluid">
      {/* Header */}
      <div className="row bg-primary px-header py-5">
        <div className="col-md-5 col-lg-6 my-auto">
          <h1 className="text-secondary">NUESTROS SERVICIOS</h1>
          <p className="m-0 text-white ps-3 fs-5">
            Proporcionamos servicios puntuales y de excelencia.
          </p>
        </div>
        <div className="col-10 col-md-6 offset-md-1 mx-auto mt-4 my-md-auto">
          <img src={headerServices} alt="headerServices" width={"100%"} />
        </div>
      </div>
      {/* Servicios */}
      <div className="row py-5">
        <div className="col text-center">
          <h1 className="m-0">¡Contamos con diferentes servicios!</h1>
        </div>
      </div>
      {/* Servicios 1 */}
      <div className="row mb-5">
        <div className="col-11 col-lg-10 bg-secondary mx-auto py-5">
          <div className="row">
            <div className="col-11 col-md-8 col-lg-6 mx-auto mb-4 mb-lg-0">
              <img src={service1} alt="service1" width={"100%"} />
            </div>
            <div className="col-lg-6 text-center m-auto">
              <h2>Alquiler de Autos Particulares</h2>
              <p className="fs-5">
                Para el Traslado de personas en autos económicos (compartidos)
              </p>
              <div className="row">
                <div className="col-md-1 ms-auto my-auto">
                  <i className="bi bi-car-front-fill fs-1"></i>
                </div>
                <div className="col-md-6 my-auto me-auto">
                  <span className="fs-5">Quito - Ibarra Ibarra - Quito</span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-1 ms-auto my-auto">
                  <i className="bi bi-clock-fill fs-1"></i>
                </div>
                <div className="col-md-6 me-auto my-auto">
                  <p className="m-0 fs-5">05:00 am 09:00 am</p>
                  <p className="m-0 fs-5">13:00 pm 17:00 pm 20:00 pm</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Servicios 2 */}
      <div className="row mb-5">
        <div className="col-11 col-lg-10 bg-primary mx-auto py-5 p-md-5">
          {/* Seccion 1 */}
          <div className="row px-4 px-md-5 mb-5">
            <div className="col-lg-6 m-auto">
              <h2 className="mb-4 text-center text-white">
                Recepción y Entrega de Paquetes
              </h2>
              <div className="row mb-3">
                <div className="col-2 col-lg-1 my-auto mx-auto me-lg-3 text-center">
                  <i className="bi bi-calendar-check-fill fs-1 text-white"></i>
                </div>
                <div className="col my-auto">
                  <span className="text-white fs-5">
                    365 días del año (aplica restricciones).
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-2 col-lg-1 my-auto mx-auto me-lg-3 text-center">
                  <i className="bi bi-clock-fill fs-1 text-white"></i>
                </div>
                <div className="col my-auto">
                  <span className="text-white fs-5">
                    09:00 am 13:00 pm 17:00 pm
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 mx-auto mt-4 mt-lg-0">
              <img src={service2} alt="service1" width={"100%"} />
            </div>
          </div>
          {/* Seccion 2 */}
          <div className="row px-4 mb-3">
            <div className="col-md-6">
              <ul>
                <li className="text-white fs-5">
                  Recolección de sus paquetes en su domicilio, comercio u
                  oficina.
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul>
                <li className="text-white fs-5">
                  Manejo de profesional de sus paquetes y documentos. ¡ Los
                  tratamos como si fueran nuestros!
                </li>
              </ul>
            </div>
          </div>
          {/* Seccion 3 */}
          <div className="row px-4 mb-3">
            <div className="col-md-6">
              <ul>
                <li className="text-white fs-5">
                  Manejo de profesional de sus paquetes y documentos. ¡ Los
                  tratamos como si fueran nuestros!
                </li>
              </ul>
            </div>
            <div className="col-md-6">
              <ul>
                <li className="text-white fs-5">
                  Servicio Express con entrega garantizada antes de 24 horas.
                </li>
              </ul>
            </div>
          </div>
          {/* Seccion 4 */}
          <div className="row px-4">
            <div className="col">
              <ul>
                <li className="text-white fs-5">
                  Descuentos especiales a Mayoristas, vendedores por Internet y
                  empresas.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Servicios 3 */}
      <div className="row mb-5">
        <div className="col-11 col-lg-10 bg-secondary mx-auto p-5">
          <div className="row">
            <div className="col-md-8 col-lg-6 mx-auto mb-4 my-lg-auto">
              <img src={service3} alt="service1" width={"100%"} />
            </div>
            <div className="col-lg-6 m-auto">
              <h2 className="text-center mb-3">
                Y para que estés siempre seguro, te ofrecemos en todos nuestros
                translados
              </h2>
              <div className="row px-3">
                <ul className="m-0 fs-5">
                  <li className="mb-3">
                    Atención personalizada por vía telefónica en nuestros
                    horarios, y chat desde las 07:00 am hasta las 19:00 pm, los
                    365 días del año.
                  </li>
                  <li className="mb-3">Personal altamente calificado.</li>
                  <li className="mb-3">
                    Reservaciones y pedidos mediante nuestra aplicación móvil.
                  </li>
                  <li>
                    Seguimiento a nuestros automotores por medio de GPS en
                    tiempo real.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Texto */}
      <div className="row mb-5">
        <div className="col-11 col-lg-10 text-center m-auto bg-primary py-4 px-5">
          <span className="text-white fs-5">
            Somos conscientes de la necesidad de proteger a nuestro planeta, por
            ello nuestros vehículos de traslados están equipados con la
            <strong className="text-white"> tecnología Euro III</strong>.
          </span>
        </div>
      </div>
      {/* Tarifas */}
      <div className="row bg-primary text-center py-5 p-md-5">
        <div className="col-lg-10 m-auto py-5">
          {/* Titulo 1 */}
          <div className="row mb-3">
            <div className="col">
              <h2 className="text-white">
                Nuestras tarifas en el Servicio Compartido
              </h2>
              <p className="text-white fs-5">
                Conoce nuestros precios desde diferentes puntos de la ciudad de
                Quito hacia Ibarra, en traslado de personas en servicio
                compartido como en las respectivas encomiendas.
              </p>
            </div>
          </div>
          {/* Tabla 1 */}
          <div className="row mb-5">
            <div className="col-11 col-lg-10 mx-auto">
              <div className="table-responsive px-3 bg-white rounded-2 container-table">
                <table className="table border-primary">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">TRANSLADO EN AUTOS COMPARTIDOS</th>
                      <th scope="col">SERVICIO DE ENCOMIENDAS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Quito Norte hasta Ibarra</th>
                      <td>desde $17.00 por persona</td>
                      <td>desde $10.00 por encomienda</td>
                    </tr>
                    <tr>
                      <th scope="row">Quito Sur (Pintado) hasta Ibarra</th>
                      <td>desde $23.00 por persona</td>
                      <td>desde $15.00 por encomienda</td>
                    </tr>
                    <tr>
                      <th scope="row">Quito Sur (Quitumbe) hasta Ibarra</th>
                      <td>desde $25.00 por persona</td>
                      <td>desde $20.00 por encomienda</td>
                    </tr>
                    <tr>
                      <th scope="row">Quito Sur (Guamaní) hasta Ibarra</th>
                      <td>desde $30.00 por persona</td>
                      <td>desde $20.00 por encomienda</td>
                    </tr>
                    <tr>
                      <th scope="row">Valles de los Chillos hasta Ibarra</th>
                      <td>desde $25.00 por persona</td>
                      <td>desde $20.00 por encomienda</td>
                    </tr>
                    <tr>
                      <th scope="row">Cumbayá, Tumbaco hasta Ibarra</th>
                      <td>desde $23.00 por persona</td>
                      <td>desde $15.00 por encomienda</td>
                    </tr>
                    <tr>
                      <th scope="row">Aeropuerto hasta Ibarra</th>
                      <td>desde $25.00 por persona</td>
                      <td></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {/* Titulo 2 */}
          <div className="row mb-3">
            <div className="col">
              <h2 className="text-white">
                Nuestras tarifas en el Servicio Exclusivo
              </h2>
              <p className="text-white fs-5">
                Conoce nuestros precios desde Quito e Ibarra hacia diferentes puntos del país
              </p>
            </div>
          </div>
          {/* Tabla 2 */}
          <div className="row">
            <div
              className="col-11 col-lg-10 mx-auto"
              style={{ height: "35rem" }}
            >
              <div className="table-responsive px-3 bg-white rounded-2 container-table">
                <table className="table border-primary">
                  <thead>
                    <tr>
                      <th scope="col">CIUDAD</th>
                      <th scope="col">NORTE</th>
                      <th scope="col">CENTRO</th>
                      <th scope="col">SUR</th>
                      <th scope="col">IBARRA</th>
                    </tr>
                  </thead>
                  <tbody>
                    {exclusivePrices.map((e, index) => (
                      <tr key={index}>
                        <th scope="row" className="text-capitalize">
                          {e.ciudad}
                        </th>
                        <td>$ {(+e.norte).toFixed(2)}</td>
                        <td>$ {(+e.centro).toFixed(2)}</td>
                        <td>$ {(+e.sur).toFixed(2)}</td>
                        <td>$ {(+e.ibarra).toFixed(2)}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Titulo */}
      <div className="row text-center py-4 py-lg-5">
        <div className="col">
          <h1>Ademas te ofrecemos.</h1>
        </div>
      </div>
      {/* Ofrecemos */}
      <div className="row mb-5">
        <div className="col-11 col-lg-10 bg-primary m-auto">
          <div className="row pt-4">
            <div className="col-md-7 col-lg-5 text-center m-auto p-4 p-lg-auto">
              <h2 className="mb-4 text-white">Auto ejecutivo en tu ciudad</h2>
              <p>Dentro de Quito, Ibarra, Atuntaqui y Otavalo.</p>
              <ul className="text-start m-0 fs-5">
                <li className="mb-3 text-white">
                  Contamos con taxímetro digital en la app móvil. ¡Paga lo que
                  marca la app del punto A al punto B!
                </li>
                <li className="mb-3 text-white">
                  Emitimos recibos electrónicos para constancia de su traslado.
                </li>
                <li className="mb-3 text-white">
                  Manejamos créditos empresariales hasta 15 días hábiles,
                  mediante váuchers de servicio.
                </li>
              </ul>
            </div>
            <div className="col-6 col-md-5 col-lg-4 mx-auto mt-auto">
              <img src={appMovil} alt="appMovil" width={"100%"} />
            </div>
          </div>
        </div>
      </div>
      {/* Recorridos */}
      <div className="row mb-5">
        <div className="col-11 col-lg-10 m-auto bg-secondary">
          <div className="row py-5">
            <div className="col-11 col-md-8 col-lg-6 mb-4 my-lg-auto">
              <img
                src={service4}
                alt="service4"
                width={"100%"}
                className="img-service4"
              />
            </div>
            <div className="col-md-10 m-auto col-lg-6 text-center px-5">
              {/* Titulo */}
              <div className="row mb-3">
                <div className="col text-dark">
                  <h2>Servicio empresarial a nivel nacional</h2>
                </div>
              </div>
              {/* Seccion 1 */}
              <div className="row mb-3 bg-primary py-4 justify-content-center">
                <div className="col-md-2 my-auto">
                  <i className="bi bi-car-front-fill fs-1 text-white"></i>
                </div>
                <div className="col-md-8 my-auto fs-5">
                  <p className="text-white">Alquiler de Autos Particulares</p>
                  <hr className="text-white" />
                  <span className="text-white">
                    Contamos con cobros fijos por hora dentro del Distrito
                    Metropolitano de Quito
                  </span>
                </div>
              </div>
              {/* Seccion 2 */}
              <div className="row mb-3 bg-primary py-4 justify-content-center">
                <div className="col-md-2 my-auto">
                  <i className="bi bi-clock-fill fs-1 text-white"></i>
                </div>
                <div className="col-md-8 my-auto fs-5">
                  <p className="text-white">
                    $8.00/Hora de alquiler de autos particulares
                  </p>
                  <hr className="text-white" />
                  <span className="text-white">
                    $10.00/hora desde zonas periféricas
                  </span>
                </div>
              </div>
              {/* Seccion 3 */}
              <div className="row bg-primary p-4">
                <div className="col">
                  <h3 className="text-white">
                    Esperamos cubrir sus necesidades, de usted y de su empresa.
                  </h3>
                  <hr className="text-white" />
                  <span className="text-white fs-5">
                    Con tu tarje de crédito preferida
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Descarga */}
      <SectionQr />
    </div>
  );
};
