import "./Navbar.scss";
import { useEffect } from "react";
import logo from "../../../assets/logo.png";

export const Navbar = () => {
  useEffect(() => {
    const currentUrl = window.location.pathname.split("/").pop();

    document.querySelectorAll(".custom-active").forEach((element) => {
      if (currentUrl === element.href.split("/").pop()) {
        element.classList.add("text-secondary");
      }
    });
  }, []);

  return (
    <nav className="navbar navbar-expand-lg bg-light shadow">
      <div className="container-fluid">
        <a href="/" className="navbar-brand">
          <img src={logo} alt="logo" width="250" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {/* menu */}
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <h6>
                <a href="/" className="nav-link custom-active link-primary">
                  Inicio
                </a>
              </h6>
            </li>
            <li className="nav-item">
              <h6>
                <a
                  href="/servicios"
                  className="nav-link custom-active link-primary"
                >
                  Servicios
                </a>
              </h6>
            </li>
            <li className="nav-item">
              <h6>
                <a
                  href="/contactos"
                  className="nav-link custom-active link-primary"
                >
                  Contactos
                </a>
              </h6>
            </li>
            <li className="nav-item">
              <h6>
                <a
                  href="/empresa"
                  className="nav-link custom-active link-primary"
                >
                  Empresa
                </a>
              </h6>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
