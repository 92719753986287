import "./ButtonTop.scss";

import React from "react";

export const ButtonTop = () => {
  React.useEffect(() => {
    validateShow();

    window.addEventListener("scroll", validateShow);
  }, []);

  const validateShow = () => {
    if (window.pageYOffset !== 0) {
      document.getElementById("btn-scroll").classList.add("show-scrollTop");
      document.getElementById("btn-scroll").classList.remove("d-none");
    } else {
      document.getElementById("btn-scroll").classList.remove("show-scrollTop");
      document.getElementById("btn-scroll").classList.add("d-none");
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div
        id="btn-scroll"
        className="to-top bg-secondary rounded-circle border border-2 boder-white d-none"
        onClick={scrollToTop}
      >
        <i className="bi bi-chevron-up fs-4 text-white"></i>
      </div>
    </div>
  );
};
