import "./Clients.scss";

import clientes from "./../../assets/images/home/clients/clientes.json";

export const Clients = () => {
  const sizeDistribuidor = clientes.images.length;
  const widthLogtipo = 250;
  const animationSpeed = 70;

  const scrollStyle = (
    <style>
      {`
        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(calc(-${widthLogtipo}px * ${sizeDistribuidor}));
          }
        }
     `}
    </style>
  );

  const styles = {
    slideTrack: {
      animation: `scroll ${animationSpeed}s linear infinite`,
      display: "flex",
      width: `calc(${widthLogtipo} * ${sizeDistribuidor * 2})`,
    },
  };

  return (
    <div className="row">
      {scrollStyle}
      <div className="col p-0">
        <div className="slider">
          <div style={styles.slideTrack}>
            {clientes.images.map((imgName, index) => (
              <div key={index} className="slide">
                <img
                  src={require(`./../../assets/images/home/clients/${imgName}.png`)}
                  alt={imgName}
                />
              </div>
            ))}
            {clientes.images.map((imgName, index) => (
              <div key={index} className="slide">
                <img
                  src={require(`./../../assets/images/home/clients/${imgName}.png`)}
                  alt={imgName}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
