import appMovil from "../../../assets/images/app-movil.png";

import qrAndroid from "../../../assets/images/qr-android.png";
import qrApple from "../../../assets/images/qr-apple.png";

export const SectionQr = ({ bgColor, titleColor, textColor }) => {
  return (
    <div className={`row text-center pt-lg-5 ${bgColor} ${textColor}`}>
      <div className="col-lg-6 ms-md-auto py-5">
        <h2 className={`${titleColor}`}>¡Descarga nuestra aplicación móvil!</h2>
        <h3 className={`${textColor}`}>Escanea nuestros códigos QR</h3>
        <p className={`${textColor} fs-5 mb-4`}>
          Contamos con aplicación para Android y Apple
        </p>
        <div className="row mt-lg-5">
          <div className="col-9 col-md-6 m-auto">
            <img src={qrAndroid} alt="qrAndroid" width={"50%"} />
            <p>
              <a
                href="https://bit.ly/NuestraAplicación"
                className={`${textColor} fs-5`}
                target="_blank"
                rel="noreferrer"
              >
                https://bit.ly/NuestraAplicación
              </a>
            </p>
          </div>
          <div className="col-9 col-md-6 m-auto">
            <img src={qrApple} alt="qrApple" width={"50%"} />
            <p>
              <a
                href="https://aple.co/36aBV5D"
                className={`${textColor} fs-5`}
                target="_blank"
                rel="noreferrer"
              >
                https://aple.co/36aBV5D
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="col-5 col-lg-4 mx-auto mt-lg-auto">
        <img src={appMovil} alt="appMovil" width={"100%"} />
      </div>
    </div>
  );
};
