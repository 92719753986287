import "./App.scss";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Footer } from "./components/shared/Footer";
import { Home } from "./pages/Home";
import { Services } from "./pages/Services";
import { Contacts } from "./pages/Contacts";
import { Business } from "./pages/Business";
import { Navbar } from "./components/shared/Navbar/Navbar";

function App() {
  return (
    <Router>
      <div className="bg-light">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/servicios" element={<Services />} />
          <Route path="/contactos" element={<Contacts />} />
          <Route path="/empresa" element={<Business />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
