export const SectionContacts = ({ bgColor, textColor }) => {
  return (
    <div className={`row ${bgColor} py-5 justify-content-center`}>
      <div className="col-lg-4 my-auto">
        {/*-- Title --*/}
        <div className="row mb-4 text-center">
          <div className="col">
            <h1 className={`${textColor}`}>¡Contáctanos!</h1>
          </div>
        </div>
        {/*-- Contact 1 --*/}
        <div className="row mb-3">
          <div className="col-2 text-center">
            <i className={`bi bi-house-door-fill fs-1 ${textColor}`}></i>
          </div>
          <div className="col my-auto">
            <span className={`${textColor} fs-4`}>
              Av. Amaru Ñan y Calle I , Quito-Ecuador
            </span>
          </div>
        </div>
        {/*-- Contact 2 --*/}
        <div className="row mb-3">
          <div className="col-2 text-center">
            <i className={`bi bi-envelope-at-fill fs-1 ${textColor}`}></i>
          </div>
          <div className="col my-auto">
            <span className={`${textColor} fs-4`}>
              serviceexpresvm@gmail.com
            </span>
          </div>
        </div>
        {/*-- Contact 3 --*/}
        <div className="row mb-3">
          <div className="col-2 text-center">
            <i className={`bi bi-telephone-fill fs-1 ${textColor}`}></i>
          </div>
          <div className="col my-auto">
            <span className={`${textColor} fs-4`}>099 986 9926</span>
          </div>
        </div>
        {/*-- Contact 4 --*/}
        <div className="row mb-3">
          <div className="col-2 text-center">
            <i className={`bi bi-telephone-fill fs-1 ${textColor}`}></i>
          </div>
          <div className="col my-auto">
            <span className={`${textColor} fs-4`}>098 188 8449</span>
          </div>
        </div>
      </div>
      <div className="col-lg-6 p-3 rounded-3">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7670926222904!2d-78.55245878531483!3d-0.2903447997881623!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d599ae8995f361%3A0xe8f13eee1ac9d5ef!2sV%26M%20SERVICE%20EXPRES!5e0!3m2!1ses!2sec!4v1673370045986!5m2!1ses!2sec"
          width="100%"
          height="450"
          className="rounded"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="V&M"
        ></iframe>
      </div>
    </div>
  );
};
