import "./ButtonApp.scss";

export const ButtonApp = () => {
  return (
    <div>
      <div className="rounded-circle app-circle shadow">
        <a
          href="https://bit.ly/NuestraAplicaci%C3%B3n"
          target="_blank"
          rel="noreferrer"
          title="Descarga nuestra aplicación en tu dispositivo Android"
        >
          <i className="bi bi-phone-fill fs-1 text-secondary m-auto"></i>
        </a>
      </div>
    </div>
  );
};
